<template>
  <div class="fs-medium">
    <a :class="buttonClass" @click="handleClick" :href="linkUrl" tabindex="0">
      <font-awesome-icon
        v-if="displayIcon"
        icon="sign-out-alt"
        role="presentation"
      ></font-awesome-icon>
      {{ text }}
    </a>
    <rds-modal
      @hidden="modalIsVisible = false"
      @close="hideApplyNowModal"
      :visible="modalIsVisible"
      ref="applyNowModal"
      use-custom-close
    >
      <div class="d-block p-space-md">
        <div class="d-flex justify-content-between align-items-start">
          <h3 class="mb-space-xs modal-title fw-bolder">Apply now</h3>
          <button
            type="button"
            aria-label="Close"
            class="modal-close"
            @click="hideApplyNowModal"
          >
            ×
          </button>
        </div>

        <p class="mb-space-xs">Please select one of the options below:</p>

        <NuxtLink
          target="_blank"
          href="//webapp4.asu.edu/uga_admissionsapp/"
          class="btn btn-secondary d-block fw-bold fs-medium mb-space-sm"
          @click.native="handleUndergraduateApplicationClick"
        >
          Start Undergraduate Application
        </NuxtLink>
        <NuxtLink
          target="_blank"
          href="//webapp4.asu.edu/dgsadmissions/Index.jsp"
          class="btn btn-secondary d-block fw-bold fs-medium mt-0"
          @click.native="handleGraduateApplicationClick"
        >
          Start Graduate Application
        </NuxtLink>
      </div>
    </rds-modal>
  </div>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { RdsModal } from "@rds-vue-ui/rds-modal";
import { computed, ref } from "vue";
import { VariantOption } from "~/types/types";

interface Props {
  variant?: VariantOption;
  isBlock?: boolean;
  type?: "modal" | "link";
  linkUrl?: string;
  text?: string;
  textSize?: string;
  textWeight?: string;
  eventRegion: string;
  eventSection: string;
  eventComponent?: string;
  hasSizeClass?: boolean;
  displayIcon?: boolean;
  removeButtonStyles?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: "secondary",
  isBlock: false,
  type: "modal",
  linkUrl: "#",
  text: "Apply now",
  textSize: "medium",
  textWeight: "bold",
  hasSizeClass: false,
  displayIcon: false,
});

const modalIsVisible = ref(false);

const eventComponentString = computed(() => {
  return props.eventComponent ? props.eventComponent.toLowerCase() : "";
});

const buttonClass = computed(() => {
  let result = "";

  if (props.removeButtonStyles) {
    result += `text-${props.variant} fs-${props.textSize} fw-${props.textWeight}`;
  } else {
    result += `btn btn-${props.variant} fs-${props.textSize} fw-${props.textWeight}`;

    if (props.isBlock) {
      result += " btn-block";
    }
    if (props.hasSizeClass) {
      result += " btn-size";
    }
  }

  return result;
});

const handleClick = (event: Event) => {
  event.preventDefault();

  if (props.type === "modal") {
    showApplyNowModal();
  } else {
    analyticsComposable.trackLinkEvent(
      "internal link",
      props.eventRegion.toLowerCase(),
      props.eventSection.toLowerCase(),
      props.text.toLowerCase(),
      eventComponentString.value
    );
    navigateTo(props.linkUrl, {
      open: {
        target: "_blank",
      },
    });
  }
};
const showApplyNowModal = () => {
  modalIsVisible.value = true;
  analyticsComposable.trackModalEvent(
    "onclick",
    "open",
    "click",
    props.eventRegion.toLowerCase(),
    props.eventSection.toLowerCase(),
    props.text.toLowerCase(),
    eventComponentString.value
  );
};
const hideApplyNowModal = () => {
  modalIsVisible.value = false;
  analyticsComposable.trackModalEvent(
    "onclick",
    "close",
    "click",
    props.eventRegion.toLowerCase(),
    props.eventSection.toLowerCase(),
    "close cross",
    eventComponentString.value
  );
};
const handleUndergraduateApplicationClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "apply now",
    "start undergraduate application",
    eventComponentString.value
  );
};
const handleGraduateApplicationClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "apply now",
    "start graduate application",
    eventComponentString.value
  );
};
</script>

<style lang="scss" scoped>
.btn-block {
  display: block;
  width: 100%;
}
.modal-title {
  font-size: 24px;
}

/* Small and medium devices (mobile, tablets, 768px and below) */
/* apply and request info button padding override for small and medium screens*/
@media (max-width: 768px) {
  .btn-size {
    padding: 4px 8px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .btn-size {
    padding: 8px;
    font-size: 14px;
  }
}
/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
  .btn-size {
    font-size: 14px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .btn-size {
    padding: 8px 16px;
  }
}
</style>
